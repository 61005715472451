<template>
<div>
    <b-card-actions action-collapse title="Agent Form">  
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                      <b-col
          cols="12"
          class="mb-2"
        >
          <div class="border rounded p-2">
            <h4 class="mb-1">
              Avatar
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                 id ="profile"
                  ref="refPreviewEl"
                  :src="input.avatar_pathurl"
                  height="110"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>

                <small class="text-muted">(Recommend Size : 200x200)</small>
                <b-card-text class="my-50">
                  <b-link id="blog-image-text">
                    {{ input.userFile ? input.userFile.name : 'banner.jpg' }}
                  </b-link>
                </b-card-text>
                <div class="d-inline-block">
                  <b-form-file
                    ref="refInputEl"
                    v-model="input.userFile"
                    accept=".jpg, .png, .gif"
                    placeholder="Choose file"
                    @input="inputImageRenderer"
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>Email</label>
                             <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="emails"
                               
                                >
                               <b-form-tags
                        v-model="input.emails"
                        no-outer-focus
                         placeholder="Email"
                        >
                    
                        </b-form-tags>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        
                        
                    </b-col>
                    <b-col md="6">
                         <b-form-group>
                            <label>Phone</label>
                            
                            <validation-provider
                                 #default="{ errors }"
                                rules="min:0"
                                name="phone"
                                >
                                 <b-form-tags
                        v-model="input.phones"
                        no-outer-focus
                         placeholder="Phone"
                        >
                    
                        </b-form-tags>
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>Password</label>
                            <validation-provider
                            #default="{ errors }"
                            name="Password"
                            vid="Password"
                            rules="password"
                            >
                            <b-form-input
                                v-model="input.password"
                                type="password"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Your Password"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>Confirm Password</label>
                            <validation-provider
                            #default="{ errors }"
                            name="Password Confirm"
                            rules="confirmed:Password"
                            >
                            <b-form-input
                                v-model="passwordCon"
                                :state="errors.length > 0 ? false:null"
                                type="password"
                                placeholder="Confirm Password"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>First name</label>
                             <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="firstname"
                                >
                                <b-form-input
                                    v-model="input.firstname"
                                    placeholder="First Name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        
                        
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>Last Name</label>
                            <validation-provider
                                 #default="{ errors }"
                                rules="min:0"
                                name="lastname"
                                >
                               <b-form-input
                                    v-model="input.lastname"
                                    placeholder="Last Name"
                                />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                                
                               
                        </b-form-group>
                    </b-col>
                     <b-col md="12">
                        <b-form-group>
                            <label>Comment</label>
                             <validation-provider
                                 #default="{ errors }"
                                rules="min:0"
                                name="comment"
                                >
                               <b-form-textarea
                                    v-model="input.comment"
                                    placeholder="Comment"
                                />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                               
                             
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>Address</label>
                            <validation-provider
                                 #default="{ errors }"
                                rules="min:0"
                                name="address"
                                >
                              <b-form-textarea
                                    v-model="input.address"
                                    placeholder="Address"
                                />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                               
                                
                                
                        </b-form-group>
                    </b-col>
                     <b-col md="3">
                        <b-form-group>
                            <label>Country</label>
                             <validation-provider
                                 #default="{ errors }"
                                rules="min:0"
                                name="country"
                                >
                              <b-form-input
                                    v-model="input.country"
                                    placeholder="Country"
                                />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                               
                               
                               
                        </b-form-group>
                    </b-col>
                     <b-col md="3">
                        <b-form-group>
                            <label>Active</label>
                            <validation-provider
                                 #default="{ errors }"
                                rules="required"
                                name="is_active"
                                >
                              <b-form-checkbox
                                checked="true"
                                class="custom-control-primary"
                                v-model="input.is_active"
                                switch
                            />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                           
                        </b-form-group>  
                        
                    </b-col>
                  
                    <b-col cols="12">
                        <b-button
                            variant="primary"
                            type="submit"
                            @click.prevent="validationForm"
                        >
                            Submit
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card-actions>
    <property-lists-owner />
</div>
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormTextarea,BFormRating,BFormCheckbox,BFormCheckboxGroup
} from 'bootstrap-vue'
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BImg, BFormFile, BLink,BFormTags
} from 'bootstrap-vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import BCardActions from '../../../../@core/components/b-card-actions/BCardActions.vue'
import axios from '@axios'
import PropertyListsOwner from '@/views/properties/property-list/PropertyListsOwner.vue'

//popup
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

import router from '@/router'
export default {
    components: {
        BCardActions,
        VueAutosuggest,
        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormTextarea,
        BFormRating,
        vSelect,
        BFormCheckbox,
        BFormCheckboxGroup,
         BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BImg, BFormFile, BLink,
        BFormTags,
        PropertyListsOwner
       
  },
  directives: {
    Ripple,
  },
  data(){
      return {
            id:router.currentRoute.params.id,
          input : {
              email:null,
              phone:null,
              password:null,
              firstname:null,
              lastname:null,
              comment:null,
              address:null,
              country:null,
              is_active:true,
              userFile:null,
              type_id:4,
              avatar_pathurl:null,
              avatar_url:null,
              emails:[],
              phones:[]
          },
          passwordCon: null,
          userEdit: {},
          emails:[],
          phones:[]

      }
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
  created() {
      if(this.id != undefined){
      
        this.getData();
      }
  },
  methods: {
    async getData(){
        var user = await axios.get('users/v1/detail/'+this.id);
        if(!user.data.data.id){
            this.$router.push('/agents/lists', () => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `ERror`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: `Data not Found.`,
                },
              })
          });
        }else{
            let usr = user.data.data;
            this.input.email =  usr.email;
            this.input.phone =  usr.phone;
            this.input.firstname =  usr.firstname;
            this.input.lastname =  usr.lastname;
            this.input.comment =  usr.comment;
            this.input.address =  usr.address;
            this.input.country =  usr.country;
               this.input.emails = usr.emails.map((item) => { return item.email});
             this.input.phones = usr.phones.map((item) => { return item.phone_no});
            this.input.is_active =  usr.is_active==1?true:false;
            this.input.password =  null;
             this.input.avatar_pathurl = usr.avatar_pathurl;
              this.input.avatar_url = usr.avatar_url;
              this.emails = usr.emails;
             this.phones = usr.phones;

        }
    },
    makeToast(variant = null,title,message) {
        this.$bvToast.toast(message, {
            title: title,
            variant,
            solid: true,
        })
    },
      validationForm() {
          this.$refs.simpleRules.validate().then(success => {

              if (success) {
                    this.input.base64 = '';
                  if(this.input.userFile){
                       this.input.base64 = document.getElementById('profile').src;
                  }
                   if(this.input.phones){
                    this.input.phone = this.input.phones[0];
                  
                     
                        this.input.phone_remove = this.phones.filter((itm) => { 
                            let index = this.input.emails.findIndex(item => item === itm.phone );
                           
                            if(index < 0){
                                return itm.id

                            }
                             
                            
                            //return itm.email
                        });
                         this.input.phone_add = this.input.phones.filter((itm) => { 
                            let index = this.phones.findIndex(item => item.phone === itm );
                            
                            if(index < 0){
                                return itm

                            }
                             
                            
                            //return itm.email
                        });
                        
                      
                        

                }else{
                    this.input.phone = '';
                }

                if(this.input.emails){
                    this.input.email = this.input.emails[0];
                    console.log('test',this.input);
                     
                        this.input.email_remove = this.emails.filter((itm) => { 
                            let index = this.input.emails.findIndex(item => item === itm.email );
                            console.log(index,itm.email);
                            if(index < 0){
                                return itm.id

                            }
                             
                            
                            //return itm.email
                        });
                         this.input.email_add = this.input.emails.filter((itm) => { 
                            let index = this.emails.findIndex(item => item.email === itm );
                            console.log(index,itm.email);
                            if(index < 0){
                                return itm

                            }
                             
                            
                            //return itm.email
                        });
                        
                      
                        

                    }
                  var data = JSON.stringify(this.input);
                   console.log('input', this.input);
                if(this.id != undefined){
                    axios.put('users/v1/update/'+this.id,data)
                    .then(res => { 
                      
                        if(!res.data.success){
                        this.makeToast('danger','Error 403',res.data.message);
                        
                        
                        }else{
                        this.$router.push('/agents/list', () => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                title: `Done`,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                                text: `You have successfully created.`,
                                },
                            })
                        });
                        

                        }
                    // this.actions = res.data.data; 
                    }).catch(error => {
                        this.makeToast('danger','Error 403',error);
                        console.log('error',error);
                    })
                }else{
                    axios.post('users/v1/add',data)
                    .then(res => { 
                      
                        if(!res.data.success){
                        this.makeToast('danger','Error 403',res.data.message);
                        
                        
                        }else{
                        this.$router.push('/agents/list', () => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                title: `Done`,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                                text: `You have successfully created.`,
                                },
                            })
                        });
                        

                        }
                    // this.actions = res.data.data; 
                    }).catch(error => {
                        this.makeToast('danger','Error 403',error);
                        console.log('error',error);
                    })

                }
                   //this.input.password = 'zero2n!*2520';
                  
                  //users/v1/add
                 
                 
                   
              }
          });
      }

  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>