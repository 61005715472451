var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-actions',{attrs:{"action-collapse":"","title":"Agent Form"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Avatar ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewEl",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"id":"profile","src":_vm.input.avatar_pathurl,"height":"110","width":"170"}})],1),_c('b-media-body',[_c('small',{staticClass:"text-muted"},[_vm._v("(Recommend Size : 200x200)")]),_c('b-card-text',{staticClass:"my-50"},[_c('b-link',{attrs:{"id":"blog-image-text"}},[_vm._v(" "+_vm._s(_vm.input.userFile ? _vm.input.userFile.name : 'banner.jpg')+" ")])],1),_c('div',{staticClass:"d-inline-block"},[_c('b-form-file',{ref:"refInputEl",attrs:{"accept":".jpg, .png, .gif","placeholder":"Choose file"},on:{"input":_vm.inputImageRenderer},model:{value:(_vm.input.userFile),callback:function ($$v) {_vm.$set(_vm.input, "userFile", $$v)},expression:"input.userFile"}})],1)],1)],1)],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Email")]),_c('validation-provider',{attrs:{"rules":"required","name":"emails"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-tags',{attrs:{"no-outer-focus":"","placeholder":"Email"},model:{value:(_vm.input.emails),callback:function ($$v) {_vm.$set(_vm.input, "emails", $$v)},expression:"input.emails"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Phone")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-tags',{attrs:{"no-outer-focus":"","placeholder":"Phone"},model:{value:(_vm.input.phones),callback:function ($$v) {_vm.$set(_vm.input, "phones", $$v)},expression:"input.phones"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Password")]),_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","state":errors.length > 0 ? false:null,"placeholder":"Your Password"},model:{value:(_vm.input.password),callback:function ($$v) {_vm.$set(_vm.input, "password", $$v)},expression:"input.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Confirm Password")]),_c('validation-provider',{attrs:{"name":"Password Confirm","rules":"confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"password","placeholder":"Confirm Password"},model:{value:(_vm.passwordCon),callback:function ($$v) {_vm.passwordCon=$$v},expression:"passwordCon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("First name")]),_c('validation-provider',{attrs:{"rules":"required","name":"firstname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"First Name"},model:{value:(_vm.input.firstname),callback:function ($$v) {_vm.$set(_vm.input, "firstname", $$v)},expression:"input.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Last Name")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"lastname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Last Name"},model:{value:(_vm.input.lastname),callback:function ($$v) {_vm.$set(_vm.input, "lastname", $$v)},expression:"input.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Comment")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"comment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":"Comment"},model:{value:(_vm.input.comment),callback:function ($$v) {_vm.$set(_vm.input, "comment", $$v)},expression:"input.comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Address")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":"Address"},model:{value:(_vm.input.address),callback:function ($$v) {_vm.$set(_vm.input, "address", $$v)},expression:"input.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Country")]),_c('validation-provider',{attrs:{"rules":"min:0","name":"country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Country"},model:{value:(_vm.input.country),callback:function ($$v) {_vm.$set(_vm.input, "country", $$v)},expression:"input.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Active")]),_c('validation-provider',{attrs:{"rules":"required","name":"is_active"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"checked":"true","switch":""},model:{value:(_vm.input.is_active),callback:function ($$v) {_vm.$set(_vm.input, "is_active", $$v)},expression:"input.is_active"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1),_c('property-lists-owner')],1)}
var staticRenderFns = []

export { render, staticRenderFns }